<template>
  <div :style="'height:' + minHeight + 'px;overflow:scroll'">
    <header>
      <h1>医疗行业新闻</h1>
      <p>汇集行业资讯、掌握行业动态</p>
    </header>
    <main>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公司新闻" name="first">
          <div
            class="item"
            v-for="(item, index) in newsInfoListData"
            :key="index"
            @click="gonewsdetails(item.newsId)"
          >
            <img :src="item.newsImg" alt="" />
            <h1>{{ item.newsTitle }}</h1>
            <p>{{ item.createTime }}</p>
          </div>
          <div class="btn" @click="nextPage('公司新闻')">
            <i class="el-icon-plus"></i> 加载更多 <i class="el-icon-plus"></i>
          </div>
        </el-tab-pane>
        <el-tab-pane label="行业动态" name="second">
          <div
            class="item"
            v-for="(item, index) in dynamicsListData"
            :key="index"
            @click="gonewsdetails(item.newsId)"
          >
            <img :src="item.newsImg" alt="" />
            <h1>{{ item.newsTitle }}</h1>
            <p>{{ item.createTime }}</p>
          </div>
          <div class="btn" @click="nextPage('行业动态')">
            <i class="el-icon-plus"></i> 加载更多 <i class="el-icon-plus"></i>
          </div>
        </el-tab-pane>
      </el-tabs>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from '../../components/Appfooter/footerbottom.vue'
import { getCheckedNewsInfo } from '@/api/journalism'
export default {
  components: {
    Footer
  },
  data() {
    return {
      minHeight: 0,
      activeName: 'first',
      pageNumber: 1,
      pageSize: 3,
      total: 0,
      dynamicspageNumber: 1,
      dynamicspageSize: 3,
      newsInfoListData: [],
      dynamicsListData: []
    }
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event)
    },
    async getCheckedNewsInfoList(e) {
      const { code, data } = await getCheckedNewsInfo({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        newsType: '公司动态'
      })
      if (code === '0000') {
        if (e === 'next') {
          this.newsInfoListData.push(...data.news)
          console.log(this.newsInfoListData)
        } else {
          this.newsInfoListData = data.news
        }
        console.log(data)
        // this.total = data.count
      }
    },
    async getIndustryDynamicsList(e) {
      const { code, data } = await getCheckedNewsInfo({
        pageNumber: this.dynamicspageNumber,
        pageSize: this.dynamicspageSize,
        newsType: '行业资讯'
      })
      if (code === '0000') {
        if (e === 'next') {
          this.dynamicsListData.push(...data.news)
        } else {
          this.dynamicsListData = data.news
        }
        console.log(data)
      }
    },
    nextPage(e) {
      if (e === '公司新闻') {
        this.pageNumber = this.pageNumber + 1
        this.getCheckedNewsInfoList('next')
        console.log(this.pageNumber)
      } else {
        this.pageNumber = this.pageNumber + 1
        this.getIndustryDynamicsList('next')
      }
    },
    // 去新闻详情
    gonewsdetails(newsId) {
      const news = {}
      news.newsId = newsId
      this.$router.push({
        path: '/phone/journalismdetails',
        query: news
      })
    }
  },
  created() {
    this.getCheckedNewsInfoList()
    this.getIndustryDynamicsList()
  },
  mounted(){
    this.minHeight = this.$store.state.minHeight
  }
}
</script>

<style lang="less" scoped>
header {
  background-color: #ccc;
  height: 375px;
  background: url('../../assets/images/phone/xinwenzhongxin@2x.png') no-repeat;
  background-size: 100% 100%;
  padding-left: 70px;
  h1 {
    margin: 0;
    font-size: 50px;
    color: #00051d;
    padding-top: 108px;
  }
  p {
    font-size: 26px;
    color: #2e3c4f;
    padding-top: 10px;
  }
}
main {
  background-color: #f6f5f6;
  padding: 0 46px;
  .item {
    padding: 32px;
    background-color: #fff;
    margin-top: 30px;
    img {
      width: 609px;
      height: 363px;
      object-fit: cover;
    }
    h1 {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #29262a;
      line-height: 54px;
    }
    p {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #9a979b;
    }
  }
  .btn {
    margin: auto;
    margin-top: 60px;
    margin-bottom: 100px;
    width: 278px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    background: #0c2948;
    border-radius: 8px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
    i {
      font-size: 18px;
    }
  }
  // 切换统一样式
  /deep/.el-pagination .btn-prev {
    color: #fff;
    background: #0c2948;
  }
  /deep/.el-tabs__nav-wrap::after {
    content: '';
    width: 0;
  }
  /deep/.el-tabs__item.is-active {
    color: #0c2948;
    font-weight: 700;
  }
  /deep/.el-tabs__active-bar {
    height: 4px;
    background: #0c2948;
  }
  /deep/.el-tabs__item {
    color: #929292;
  }
}
</style>
