<template>
  <div class="footer">
      <p class="phone">电话：028-85326217</p>
      <p class="email">邮箱：Email:knot@scknot.com</p>
      <p class="addr">地址：成都市高新区天府三街吉泰路666号福年广场T2-1205</p>
      <div class="btom">
        <p class="info">
          © 2011-2021 成都卡恩特医疗科技有限公司<br> <a style="color:white;" href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备16005584号-1</a>
        </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.footer {
  width: 750px;
  height: fit-content;
  background-color: #040c1b !important;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  font-size: 12px;
  overflow: hidden;
  p{
    display: block;
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #DADADB;
    padding-left: 40px;

  }
  .phone{
    margin-top: 49px;
  }
  .email{
    margin-top: 33px;
  }
  .addr{
    margin-top: 33px;
  }
  .btom {
    margin-top: 36px;
    text-align: center;
    border-top: 2px solid #19253a;
    height: 100px;
    line-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      line-height: 40px;
      padding: 0 !important;
      color: rgba(255, 255, 255, 0.42);
    }
  }
}
</style>
